.targetBalanceRowWithLiquidity
    display: flex
    padding: 0 22px!important
    justify-content: space-between!important

.liquidityInfo
    font-weight: 400
    font-size: 14px
    display: inline
    color: #495057

.swap
    :global(h3)
        padding: 0

    :global(select:disabled)
        background: #79b2db
        text-align: center

.maxButton
    top: -2px
    position: relative

.swapInput
    min-width: 0
