.withdraw
    &Header
        width: 100%

    &Content
        width: 100%
        flex-grow: 1

    &Button
        margin-bottom: 55px
