/* Pricing page */

.pricing-loading-container {
  height: 450px;
  width: 750px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricing-card {
  width: calc(100% - 225px);
  max-width: 900px;
  min-width: 760px;
  margin-top: 0;
  margin-bottom: 0;
}

.pricing-buttons {
  display: flex;
  justify-content: space-between;
}

.pricing-button {
  border-color: rgba(0, 0, 0, 0);
  margin-right: 0;
  font-size: 14px;
}

.pricing-button.minimized {
  width: 60px;
}

.pricing-button.active {
  background: #1882c4;
  cursor: default;
  box-shadow: -1px 1px 14px 0px rgba(0, 0, 0, 0.47);
}

.pricing-button.active-alt {
  background: white;
  color: #1882c4;
  box-shadow: -1px 1px 14px 0px rgba(0, 0, 0, 0.47);
}

.remove-border-radius-left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.remove-border-radius-right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
