@mixin content-corner-hack
    content: ""
    position: absolute
    transform: translateZ(1px)
    left: -30px
    bottom: 0
    width: 30px
    height: 30px

$default: 50%
$extended: 52%

.test
    font-size: 12px
    color: red

.callToAction
    width: $default
    margin-bottom: 20px
    padding: 0 15px 0 0
    transform: translateZ(1px)

    &.extended
        width: 100% - $extended

.withdraw
    box-sizing: border-box
    max-width: calc(#{$default} - 20px)
    background: linear-gradient(to bottom, var(--curved-transition-gradient-start) 0%, var(--curved-transition-gradient-start) 90%, var(--curved-transition-gradient-end) 100%)
    border-radius: 18px 18px 0 0
    flex: 0 0 50%
    padding: 0 15px
    position: relative
    &.extended
        max-width: calc(#{$extended} - 20px)
        width: $extended

    &Content
        display: flex
        flex-wrap: wrap
        flex-direction: column
        background: transparent

    &::before
        @include content-corner-hack
        background: linear-gradient(to bottom, var(--curved-transition-gradient-start) 0%, var(--curved-transition-gradient-end) 100%)

    &::after
        @include content-corner-hack
        transform: translateZ(1px)
        background: var(--curved-transition-bg)
        border-radius: 0 0 100px 0

.topRow
    margin: 0
    justify-content: space-between

.bottomRow
    border-radius: 20px 0 20px 20px
    margin-top: 0
    background: linear-gradient(to left, var(--gradient-transition-start) 0%, var(--gradient-transition-start) 55%, var(--gradient-transition-end) 100%)

    .left, .right
        padding: 0 15px
        width: $default

    &.extended
        .left
            width: 100% - $extended
        .right
            width: $extended

@media only screen and (max-width: 767px)
    .callToAction
        padding-right: 0

    .withdraw
        max-width: 100%
        background: #fff
        border-radius: 18px
        margin-bottom: 20px
        &::before, &::after
            content: none

    .bottomRow
        border-radius: 18px

@media (min-width: 767px) and (max-width: 780)
    .callToAction
        width: 100%
