@mixin titlePreset {
    color: var(--blue);
    font-weight: bold;
}

.user {
    @include titlePreset();
}

.leaderboard {
    margin: auto;
    max-width: 767px;
    text-align: left;;
    width: 100%;

    &Section {
        padding-top: 24px;
        &:nth-child(3){
            padding-top: 70px;
        }
    }
}

.title {
    @include titlePreset();
    font-size: 36px;
}

.list {
    width: 100%;
    table-layout: fixed;

    &Title {
        @include titlePreset();
        color: var(--black);
        font-size: 20px;
    }
    &Row {
        font-size: 20px;
    }

    :global(td), :global(th) {
        &:first-child {
            width: 12%;
        }
        &:nth-child(2) {
            padding-left: 15px;
        }
        &:nth-child(3) {
            width: 25%;
            padding-left: 15px;
        }
    }
    :global(td) {
        &:first-child {
            padding-left: 10px;
        }
        &:nth-child(3) {
            padding-left: 25px;
        }
    }
}
