.test-message
    border: 1px solid var(--test-border)
    border-radius: 5px
    background: var(--test-bg)
    color: var(--text)
    margin: auto
    padding: 10px
    text-align: center
    width: fit-content

// used to disable a section of the app
.disable-overlay
    cursor: not-allowed
    opacity: 0.5
    position: relative
    z-index: 10
    height: fit-content
    top: 0

    &::after
        content: ' '
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        z-index: 10

    input
        cursor: not-allowed

// use to disable the entire page
.disable-overlay-full
    cursor: not-allowed
    opacity: 0.5
    position: relative
    z-index: 10

    &::after
        content: ' '
        position: absolute
        top: 0
        left: 0
        width: 100vw
        height: 100vh
        z-index: 10

    input
        cursor: not-allowed
